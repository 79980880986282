<template>
  <div class="mar-top-60" v-nk-loading="loading">
    <div class="header-bg-wrap">
      <div class="page-title adaptive-width">{{ $t('home.aboutUs') }}</div>
    </div>
    <div class="about-max adaptive-width d-flex">
      <div class="item-1 flex-1 d-flex align-items-center">
        <img class="item-about-img" src="https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/0d4e494e3fdcb424184ec4e70590c0f7.png" alt="logo" />
      </div>
      <div class="item-2 flex-3">
        <h2 class="sub-title">欢迎来到 gogo2go - 您的一站式外卖点餐平台！</h2>
        <div class="d-flex">
          <p class="mar-32" style="width:50%;font-size: 16px;">我们的口号是 "Everything to go!"，旨在为用户提供便捷的外卖点餐体验。作为一家专注于外卖服务的平台，目前涵盖的国家有马来西亚，未来计划推广到东南亚每个国家。我们致力于为用户提供安全、高效和准时的服务。</p>
          <p style="width:50%;font-size: 16px;">作为一款外卖点餐软件平台，gogo2go 提供了一键下单的便捷功能，让您可以随时随地享受美味佳肴。我们拥有自营的骑手团队，确保您的订单能够快速送达，并且保持食品的新鲜和质量。</p>
        </div>
		<div class="d-flex">
		  <p class="mar-32" style="width:50%;font-size: 16px;">作为一款外卖点餐软件平台，gogo2go 提供了一键下单的便捷功能，让您可以随时随地享受美味佳肴。我们拥有自营的骑手团队，确保您的订单能够快速送达，并且保持食品的新鲜和质量。</p>
		  <p style="width:50%;font-size: 16px;">我们深知用户对于服务的重要性，因此我们拥有本土化的客服团队，随时准备回答您的疑问、解决您的问题，确保您在使用我们平台时得到优质的支持和服务。</p>
		</div>
		<div class="d-flex">
		  <p class="mar-32" style="width:50%;font-size: 16px;">为了进一步提升外卖行业的发展，我们还提供骑手运力API平台，为其他外卖平台提供运力支持。通过我们的API平台，其他平台可以轻松接入我们的骑手团队，充分利用我们的运力资源，提高他们的服务能力和覆盖范围。</p>
		  <p style="width:50%;font-size: 16px;">在 gogo2go，我们不仅关注点餐的便利性，还注重用户的安全和满意度。我们采用先进的技术和安全措施，确保您的个人信息和支付数据的安全性。</p>
		</div>
		<div class="d-flex">
		  <p class="mar-32" style="width:50%;font-size: 16px;">我们致力于成为您信赖的外卖点餐合作伙伴，为您提供便捷、安全、高效和准时的外卖服务。无论您是在家、在办公室还是在外出，gogo2go都将是您最可靠的外卖点餐选择。</p>
		  <p style="width:50%;font-size: 16px;">欢迎加入 gogo2go，享受便捷的外卖点餐体验！</p>
		</div>
      </div>
    </div>
    <div class="adaptive-width" style="margin-bottom: 28px;">
      <img style="width: 100%;" src="https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/32fcddf6395ed182942a55ec4b64580c.png" alt />
    </div>
   <!-- <div style="background-color: #F2F5FA;" v-if="activities">
      <div class="adaptive-width d-flex pad-100-0">
        <div class="item-1" style="width: 480px;">
          <h2>{{ $t('home.developmentPath') }}</h2>
        </div>
        <div class="item-2">
          <el-timeline :reverse="reverse">
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :timestamp="activity.timestamp"
            >{{ activity.content }}</el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div> -->
    <div class="home-module home-banner">
      <div class="home-module-word">
        <div class="home-module-title">
          <h2 class="home-module-title">加入GOGO2GO，轻松开店</h2>
        </div>
        <button class="cta cursor-pointer" @click="toSettled()">
          <span class="hover-underline-animation">立即入驻</span>
          <i class="el-icon-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reverse: false,
      activities: [],
      aboutImage: '',
      aboutLeft: '',
      aboutRight: '',
      aboutTitle: '',
      en_aboutLeft: '',
      en_aboutRight: '',
      en_aboutTitle: '',
      topImage: '',
      companyLogo: '',
      companyCompany: '',
      companyAboutUs: '',
      en_companyCompany: '',
      en_companyAboutUs: '',
      bottomImg: '',
      loading: false,
    }
  },
  mounted() {
    // this.getList()
  },
  computed: {
    getLangType() {
      return function (key) {
        switch (this.$i18n.locale) {
          case 'en_US':
            return this['en_' + key]
          case 'zh_CN':
            return this[key]
          default:
            return this[key]
        }
      }
    }
  },
  methods: {
    getList() {
      const data = {
        type: 7
      }
      this.loading = true
      this.$api.getProductList(data).then(res => {
        // console.log("图片", res)
        this.aboutImage = res.data.aboutMe[0].aboutImg || ''
        this.aboutLeft = res.data.aboutMe[0].leftContent || ''
        this.aboutRight = res.data.aboutMe[0].rightContent || ''
        this.aboutTitle = res.data.aboutMe[0].title || ''
        this.en_aboutLeft = res.data.aboutMe[0].en_leftContent || ''
        this.en_aboutRight = res.data.aboutMe[0].en_rightContent || ''
        this.en_aboutTitle = res.data.aboutMe[0].en_title || ''
        this.topImage = res.data.topImage[0].topImage || ''
        this.companyLogo = res.data.introduce[0].logo || ''
        this.companyCompany = res.data.introduce[0].company || ''
        this.companyAboutUs = res.data.introduce[0].aboutUs || ''
        this.en_companyCompany = res.data.introduce[0].en_company || ''
        this.en_companyAboutUs = res.data.introduce[0].en_aboutUs || ''
        this.bottomImg = res.data.bottomImg[0].bottomImg || ''
        this.activities = res.data.developingCourse || ''
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    toSettled() {
      // this.$router.push({ path: '/settled' })
    }
  }
}
</script>

<style scoped>
.item-about-img {
  width: 242px;
}
/*@media screen and (max-width: 997px) {*/
/*  .about-max {*/
/*    flex-wrap: wrap;*/
/*  }*/
/*  .adaptive-width {*/
/*    flex-wrap: wrap;*/
/*  }*/
/*  .item-about-img {*/
/*    width: 100%;*/
/*  }*/
/*  .itemImage {*/
/*    width: 100%;*/
/*  }*/
/*  .values-list {*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*  }*/
/*  .home-module-title {*/
/*    font-size: 24px;*/
/*  }*/
/*  .mar-top-60 {*/
/*    margin-top: 50px;*/
/*  }*/
/*  .flex-1 {*/
/*    flex: 0%;*/
/*  }*/
/*  .flex-2 {*/
/*    flex: 0%;*/
/*  }*/
/*}*/
</style>
